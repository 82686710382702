import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Alert,
  Container,
  Button
} from "react-bootstrap";
import { toast } from "react-toastify";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import {
  editCampaign,
  getCampaign,
  getEstimatedPosition,
} from "../../services/adv";
import { modules } from "../../modules";
import { blankPageMap } from "../../modules/maps";
import {
  getInventoryCampaignByInventoryAndCampaign,
  getPeriodBrandingPPCAuctions,
  getPeriodLivePPCAuctions,
  verifyCustomerToken,
} from "../../services/micro";
import {
  AuctionCampaignStatusCardModule
} from "../../modules/AuctionCampaignStatusCard";
import { mixpanelInstance } from "../../mixpanel/instance";
import { GetPubAbbr } from "../../helpers/get-pub-abbr";
import pageSvg from "../../assets/svg/server_down.svg";
import { AuctionCreditBalanceCardModule } from "../../modules/AuctionCreditBalanceCard";
import { AuctionMonthlyBudgetCardModule } from "../../modules/AuctionMonthlyBudgetCard";
import { getPublisher } from "../../services/pub";
import { AgPPCTable } from "./components/AgPPCTable";
import CampaignBudget from "./components/CampaignBudget";
import PPCManagmentModal from "./components/PPCManagmentModal";
import { ClusterPPCAuctions } from "./components/Cluster/ClusterPPCAuctions";
import getPeriodClusterPPCAuctions from "../../services/micro/get_cluster_ppc_auctions";
import { BrandingPPCAuctions } from "./components/BrandingPPCAuctions";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

function getDaysDiff(ppc_invoice_day, date1, date2) {
  date1 = new Date(date1);
  date2 = new Date(date2);

  if (date1.getDate() < ppc_invoice_day) {
    date2.setMonth(date2.getMonth() - 1);
  }

  if (date1.getDate() == ppc_invoice_day) {
    return Math.round((date2 - date1) / (1000 * 3600 * 24));
  }

  if (ppc_invoice_day == 1 && date1.getDate() === 31) {
    return 1;
  }

  const diffInDays = (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
  return Math.round(diffInDays);
}

const LIVE_STATUS = 'Live'

const HTR_STAGE_PUBLISHER_ID = 10;
const HTR_PUBLISHER_ID = 67;
const DEFAULT_ID = 1;

const CREDIT_CARD_TOP_UP = 1;
const INVOICE_TOP_UP = 4;

export default function AdvertiserPpc({ isIframe }) {
  const urlParams = new URLSearchParams(window.location.search);
  const campaignId = urlParams.get("campaignId");
  const token = urlParams.get("token");

  const isIframed = window !== window.parent;

  const [inventoryCampaign, setInventoryCampaign] = useState(null);
  const [estimatedPosition, setEstimatedPosition] = useState(null);
  const [campaign, setCampaign] = useState({});
  const [newBudget, setNewBudget] = useState(0);
  const [updatePage, setUpdatePage] = useState(0);
  const [updateBudget, setUpdateBudget] = useState(0);
  const [publisherId, setPublisherId] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [closedAlert, setClosedAlert] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [oldInventoryCampaign, setOldInventoryCampaign] = useState(null);
  const [isClusterInventoryCampaign, setIsClusterInventoryCampaign] = useState(false);
  const [allChildrenCount, setAllChildrenCount] = useState(0);
  const [anyActiveChild, setAnyChildrenActive] = useState(false);
  const [clusterLevel, setClusterLevel] = useState(0);
  const [isTokenVerified, setIsTokenVerified] = useState(null);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [ppcActiveAuctions, setPpcActiveAuctions] = useState();
  const [clusterActiveAuctions, setClusterActiveAuctions] = useState();
  const [clusterInheritedAuctionsAndNotShown, setClusterInheritedAuctionsAndNotShown] = useState([]);
  const { updateCookiesProperty, removeCookiesProperty, cookiesData } = useCookiesContext();

  const gridRef = useRef();
  const publisherIdByAbbr = GetPubAbbr();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      verifyCustomerToken(token).then((response) => {
        if (response && response.success) {

          setIsTokenVerified(true);
          getCampaign(campaignId, token).then((res) => {
            if (!res || res.success === false) {
              return modules(10, blankPageMap);
            }

            console.log('getCampaign 1', response);
            setPublisherId(Number(response?.customer?.publisher_id));
            getPublisher(response?.customer?.publisher_id).then((res) => {
              if (res.success) {
                setPublisher(res.data);
              }

            }).catch((err) => {
              console.log(err);
            });

            const pubId = cookiesData[CookiesKeyNames.publisherId];

            if (!pubId) {
              updateCookiesProperty(CookiesKeyNames.publisherId, response?.customer?.publisher_id)

              window.location.reload();
            }

            if (pubId != response?.customer?.publisher_id) {
              updateCookiesProperty(CookiesKeyNames.publisherId, response?.customer?.publisher_id)

              window.location.reload();
            }

            setCampaign({ ...res.data, ...res?.table });
            setNewBudget(res.data.ppc_monthly_budget);

            getPeriodLivePPCAuctions({ search: "" }, campaignId).then(
              (response) => {
                console.log('getPeriodLivePPCAuctions', response);
                if (response.success) {
                  setPpcActiveAuctions(response.data.rows.filter((auction) => {
                    return auction[8] == LIVE_STATUS;
                  }));
                }
              }
            );
          });
        } else {
          return setIsTokenVerified(false);
        }
      });
    }
  }, [token]);

  const navigateTo404 = () => {
    if (!isIframe) {
      const publisherIdByAbbr = GetPubAbbr();
      return navigate("pages/error-404?pub=" + publisherIdByAbbr);
    }
  };

  const handleCampaignActivate = () => {
    window.location.href = "/adv/campaign/activate?campaignId=" + campaignId;
  };

  useEffect(() => {
    const getCampaignData = async () => {
      try {
        let res;
        if (isIframe && isTokenVerified) {
          res = await getCampaign(campaignId, token);
        } else if (!isIframe) {
          res = await getCampaign(campaignId);
        }

        if (cookiesData[CookiesKeyNames.publisherId]) {          
          setPublisherId(Number(cookiesData[CookiesKeyNames.publisherId]));
          getPublisher(Number(cookiesData[CookiesKeyNames.publisherId])).then((res) => {
            if (res.success) {
              setPublisher(res.data);
            }

          }).catch((err) => {
            console.log(err);
          });
        }

        if (!res || res.success === false) {
          return navigateTo404();
        }

        setCampaign({ ...res.data, ...res?.table });
        setNewBudget(res.data.ppc_monthly_budget);

        removeCookiesProperty("clickedDeactivate");
        removeCookiesProperty("clickedActivate");

        getPeriodLivePPCAuctions({ search: "" }, campaignId).then(
          (response) => {
            if (response.success) {
              setPpcActiveAuctions(response.data.rows.filter((auction) => {
                return auction[8] == LIVE_STATUS;
              }));
            }
          }
        );
      } catch (error) {
        console.log("Error fetching campaign", error);
        if (!isIframe) {
          navigateTo404();
        } else if (isIframe) {
          setErrorStatus(404);
        } else {
          console.log("Error fetching campaign", error);
          toast.error("Error fetching campaign");
        }
      }
    };

    getCampaignData();
  }, [isIframe, isTokenVerified, campaignId, token]);

  useEffect(() => {
    const getCampaignData = async () => {
      try {
        let res;
        if (isIframe && isTokenVerified) {
          res = await getCampaign(campaignId, token);
        } else if (!isIframe) {
          res = await getCampaign(campaignId);
        }

        if (cookiesData[CookiesKeyNames.publisherId]) {          
          setPublisherId(Number(cookiesData[CookiesKeyNames.publisherId]));
          getPublisher(Number(cookiesData[CookiesKeyNames.publisherId])).then((res) => {
            if (res.success) {
              setPublisher(res.data);
            }

          }).catch((err) => {
            console.log(err);
          });
        }

        if (!res || res.success === false) {
          return navigateTo404();
        }

        setCampaign({ ...res.data, ...res?.table });
        setNewBudget(res.data.ppc_monthly_budget);

        getPeriodLivePPCAuctions({ search: "" }, campaignId).then(
          (response) => {
            if (response.success) {
              setPpcActiveAuctions(response.data.rows.filter((auction) => {
                return auction[8] == LIVE_STATUS;
              }));
            }
          }
        );
      } catch (error) {
        console.log("Error fetching campaign", error);
        if (!isIframe) {
          navigateTo404();
        } else if (isIframe) {
          setErrorStatus(404);
        } else {
          console.log("Error fetching campaign", error);
          toast.error("Error fetching campaign");
        }
      }
    };

    getCampaignData();
  }, [updateBudget]);

  const [showBottom, setShowBottom] = useState(false);
  const [showTopUpBottom, setShowTopUpBottom] = useState(false);

  const handleCloseBottom = () => setShowBottom(false);

  const handleShowBottom = () => {
    mixpanelInstance.track(`Open montly budget popup for ${campaign.name}`, {
      campaignId,
    });

    setShowBottom(true);
  };

  const handleShowTopUpBottom = () => {
    mixpanelInstance.track(`Open top up popup for ${campaign.name}`, {
      campaignId,
    });

    setShowTopUpBottom(true);
  }

  const handleClosingTopUpBottom = () => {
    setShowTopUpBottom(false);
  }

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const handleClusterShow = (e) => {
    console.log("show", e);

    if (e.column.colId !== 'action') {
      return;
    }

    const parentClusterActive = e.value.split("@")[1];
    const childClusterActive = e.value.split("@")[2];
    const isParentClusterActive = parentClusterActive ? parentClusterActive == "false" : false;
    const isChildClusterActive = childClusterActive ? childClusterActive == "false" : false;

    if (e.column.colId === 'action' && parentClusterActive && !isParentClusterActive) {
      return;
    }

    const inventory_id = e.data.ID;

    getInventoryCampaignByInventoryAndCampaign(inventory_id, campaignId).then(
      (res) => {
        if (res && res.success) {
          setShow(true);
          setInventoryCampaign(res.data);
          setOldInventoryCampaign({ ...res.data });
          setIsClusterInventoryCampaign(!!parentClusterActive);
          setClusterLevel(e.node.level + 1);
          setAllChildrenCount(e.node.allChildrenCount);
          setAnyChildrenActive(isChildClusterActive);

          mixpanelInstance.track(
            `Open bid management popup for ${campaign.name}`,
            {
              campaignId,
            }
          );

          getEstimatedPosition(res.data.id, res.data.cpc)
            .then((res) => {
              setEstimatedPosition(res.data);
            })
            .catch((err) => {
              console.log("Error fetching estimated position");
            });
        } else {
          toast.warning("Cannot open bid management popup");
        }
      }
    );
  }

  const handleShow = (e) => {
    const inventory_id = e.data.ID;

    getInventoryCampaignByInventoryAndCampaign(inventory_id, campaignId).then(
      (res) => {
        if (res && res.success) {
          setShow(true);
          setInventoryCampaign(res.data);
          setOldInventoryCampaign({ ...res.data });
          setIsClusterInventoryCampaign(false);

          mixpanelInstance.track(
            `Open bid management popup for ${campaign.name}`,
            {
              campaignId,
            }
          );

          getEstimatedPosition(res.data.id, res.data.cpc)
            .then((res) => {
              setEstimatedPosition(res.data);
            })
            .catch((err) => {
              console.log("Error fetching estimated position");
            });
        } else {
          toast.warning("Cannot open bid management popup");
        }
      }
    );
  }

  const onSaveBudget = () => {
    setBudgetLoading(true);

    mixpanelInstance.track(`Try of montly budget change for ${campaign.name}`, {
      newMonthlyBudget: newBudget,
      campaignId,
    });

    const newBudgetValue = document.getElementById("blockBudget").value;

    if (newBudgetValue < 250) {
      toast.warn("Monthly budget can't be lower than $250");
      setBudgetLoading(false);
      return;
    }

    editCampaign(campaignId, {
      ppc_monthly_budget: Number(newBudgetValue),
    })
      .then((res) => {
        if (res.success === false) {
          toast.error("Error saving budget");
          return;
        }

        setShowBottom(false);
        toast.success("Budget saved successfully");

        mixpanelInstance.track(
          `Montly budget successfully changed for ${campaign.name}`,
          {
            newMonthlyBudget: newBudget,
            campaignId,
          }
        );

        setUpdateBudget(updateBudget + 1);
        setBudgetLoading(false);
      })
      .catch((err) => {
        toast.error("Error saving budget");
        setBudgetLoading(false);
      });
  };

  const onChangeMonthlyBudget = (e) => {
    setTimeout(() => {
      if (e.target.value < publisher?.min_budget || isNaN(e.target.value)) {
        toast.warn("Monthly budget can't be lower than $" + publisher?.min_budget);
        const blockBudget = document.getElementById("blockBudget");
        blockBudget.value = publisher?.min_budget;
        setNewBudget(publisher?.min_budget);
        return;
      }

      const blockBudget = document.getElementById("blockBudget");
      blockBudget.value = e.target.value;
      setNewBudget(e.target.value);
      return;
    }, 2000);
  };

  const day2 = new Date().setMonth(new Date().getMonth() + 1);
  const days_diff = getDaysDiff(
    campaign.ppc_invoice_day,
    new Date(),
    new Date(day2).setDate(campaign.ppc_invoice_day)
  );

  if (isIframe && isTokenVerified === false) {
    return modules(10, blankPageMap);
  }

  if (isIframe && !campaignId) {
    return modules(10, blankPageMap);
  }

  const campaignCategories = campaign?.related_categories_ids?.map(
    (category) => {
      const label = campaign?.columns
        ?.find((c) => c.name === "related_categories_ids")
        ?.options?.find((o) => o.value === category)?.label;
      return label;
    }
  );

  if ((isIframe && isTokenVerified === true) || !isIframe) {
    let customStyle = {};

    if (
      publisherId === HTR_PUBLISHER_ID ||
      publisherId === HTR_STAGE_PUBLISHER_ID ||
      publisherId === DEFAULT_ID
    ) {
      customStyle = {
        paddingLeft: "46px",
        paddingRight: "46px",
        paddingTop: "15px",
        paddingBottom: "15px",
      };
    }

    if (errorStatus === 404 && isIframe) {
      return (
        <div className="content">
          <Container>
            <Row className="gx-5">
              <Col lg="5" className="d-flex flex-column align-items-center">
                <h1 className="error-number">404</h1>
                <h2 className="error-title">Page Not Found</h2>
                <p className="error-text">
                  The page you were looking for doesn't exist. You may have
                  mistyped the address or the page may have moved.
                </p>
                {isIframed &&
                  <Link
                    to="/dashboard/sales"
                    className="btn btn-primary btn-error"
                  >
                    Back to Dashboard
                  </Link>
                }
              </Col>
              <Col xs="8" lg="6" className="mb-5 mb-lg-0">
                <object
                  type="image/svg+xml"
                  data={pageSvg}
                  className="w-100"
                  aria-label="svg image"
                ></object>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    let isCreditBalanceSmallerThanTopBid = Object.keys(campaign).length &&
      campaign?.ppc_credits_monthly_spend < inventoryCampaign?.top_bid &&
      campaign.ppc_active;

    let isCampaignInactive = Object.keys(campaign).length && !campaign?.ppc_active;
    let isCampaignPaused = Object.keys(campaign).length && campaign?.ppc_paused;

    // Flags for publisher
    const isHTR = publisherId === HTR_PUBLISHER_ID || publisherId === HTR_STAGE_PUBLISHER_ID;
    const isDefault = publisherId === DEFAULT_ID;

    // Flags for alerts
    let isNoBidsFlag = ppcActiveAuctions?.length === 0 && clusterActiveAuctions?.length === 0;

    // Cannot have multiple alerts at the same time
    if (isCampaignInactive) {
      isNoBidsFlag = false;
      isCreditBalanceSmallerThanTopBid = false;
      isCampaignPaused = false;
    } else if (isNoBidsFlag) {
      isCreditBalanceSmallerThanTopBid = false;
      isCampaignInactive = false;
      isCampaignPaused = false;
    } else if (isCreditBalanceSmallerThanTopBid) {
      isNoBidsFlag = false;
      isCampaignInactive = false;
      isCampaignPaused = false;
    } else if (isCampaignPaused) {
      isNoBidsFlag = false;
      isCreditBalanceSmallerThanTopBid = false;
      isCampaignInactive = false;
    }

    return (
      <React.Fragment>
        {isIframe ? <></> : <Header onSkin={setSkin} />}
        <div
          className={isIframe ? "" : "main main-app p-3 p-lg-4"}
          style={isIframe ? customStyle : {}}
        >
          {isIframe ? (
            <></>
          ) : (
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div>
                <ol className="breadcrumb fs-sm mb-1">
                  <li className="breadcrumb-item">
                    <Link href="/adv/home" to={"/adv/home"}>ADV Home</Link>
                  </li>
                  <li className="breadcrumb-item">{campaign.name}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    PPC Auctions
                  </li>
                </ol>
                <h2 className="main-title mb-0">
                  Pay-Per-Click (PPC) Auctions
                </h2>
              </div>
            </div>
          )}

          {
            (isDefault && !campaign?.ppc_active) && (
              <div className="alert alert-danger">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>Warning!</strong> Your campaign is currently inactive.
                    Please activate your campaign to start bidding on PPC inventory.
                  </div>
                  <Button variant="danger" onClick={handleCampaignActivate}>
                    Activate Campaign
                  </Button>
                </div>
              </div>
            )
          }

          <Row className="g-3">
            <Col md="12" xl="4">
              {
                AuctionCampaignStatusCardModule(publisherIdByAbbr, {
                  campaign, campaignCategories, publisherIdByAbbr, currentSkin
                })
              }
            </Col>
            <Col md="6" xl="4">
              {
                AuctionCreditBalanceCardModule(publisherIdByAbbr, {
                  campaign
                })
              }
            </Col>
            <Col md="6" xl="4" key={updateBudget}>
              {
                AuctionMonthlyBudgetCardModule(publisherIdByAbbr, {
                  campaign, budgetLoading, handleShowBottom, days_diff, handleCampaignActivate
                })
              }
            </Col>
            {
              isCreditBalanceSmallerThanTopBid ?
                (
                  !closedAlert ? (
                    <Col md="12">
                      <div class="fade alert alert-dismissible danger-system-alert show mb-0">
                        <button
                          type="button"
                          class="btn-close"
                          aria-label="Close alert"
                          onClick={() => setClosedAlert(true)}
                        ></button>
                        <div class="banner">
                          <h3 class="banner-title">
                            You don't have enough credits to continue bidding at
                            the moment (your current credit total is $0).
                          </h3>
                          <p class="banner-text">
                            Please top up on the settings page to bid on PPC
                            inventory.
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )
            }
            {
              isCampaignInactive ?
                (
                  (isHTR) ? (
                    !closedAlert ? (
                      <Col md="12">
                        <div className="fade alert alert-dismissible danger-system-alert show mb-0">
                          <button
                            type="button"
                            class="btn-close"
                            aria-label="Close alert"
                            onClick={() => setClosedAlert(true)}
                          ></button>
                          <div class="banner">
                            <h3 class="banner-title">
                              Your campaign is inactive at the moment.
                            </h3>
                            <p class="banner-text">
                              Please top-up and activate your campaign to start
                              bidding on PPC inventory
                            </p>
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )
            }
            {
              isCampaignPaused ?
                (
                  !closedAlert ? (
                    <Col md="12">
                      <div className="fade alert alert-dismissible danger-system-alert show mb-0">
                        <button
                          type="button"
                          class="btn-close"
                          aria-label="Close alert"
                          onClick={() => setClosedAlert(true)}
                        ></button>
                        <div class="banner">
                          <h3 class="banner-title">
                            Your campaign is paused at the moment.
                          </h3>
                          <p class="banner-text">
                            Please top-up and activate your campaign to start bidding on PPC inventory.
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )
            }
            {
              clusterInheritedAuctionsAndNotShown.length > 0 ?
                (
                  !closedAlert ? (
                    <Col md="12">
                      <div class="fade alert alert-dismissible warning-system-alert show mb-0">
                        <button
                          type="button"
                          class="btn-close"
                          aria-label="Close alert"
                          onClick={() => setClosedAlert(true)}
                        ></button>
                        <div class="banner">
                          <h3 class="banner-title">
                            Your ad is not shown on {clusterInheritedAuctionsAndNotShown.length} topic clusters.
                          </h3>
                          <p class="banner-text">
                            Please increase your bid to get your ad shown on these topic clusters.
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )
            }
            {
              isNoBidsFlag ?
                (
                  !closedAlert ? (
                    <Col md="12">
                      <div class="fade alert alert-dismissible danger-system-alert show mb-0">
                        <button
                          type="button"
                          class="btn-close"
                          aria-label="Close alert"
                          onClick={() => setClosedAlert(true)}
                        ></button>
                        <div class="banner">
                          <h3 class="banner-title">
                            You don't have any active bids at the moment.
                          </h3>
                          <p class="banner-text">
                            Please activate some bids to start bidding on PPC inventory.
                          </p>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )
            }
            {
              //^ Cluster PPC Auctions [DEFAULT]
              campaign && campaign.id && !isHTR &&
              <Col md="12">
                <ClusterPPCAuctions
                  updatePage={updatePage}
                  getData={getPeriodClusterPPCAuctions}
                  campaignId={campaign.id}
                  onClick={handleClusterShow}
                  setClusterActiveAuctions={setClusterActiveAuctions}
                  setClusterInheritedAuctionsAndNotShown={setClusterInheritedAuctionsAndNotShown}
                  publisherId={publisherId}
                  skin={skin}
                />
              </Col>
            }
            <Col md="12">
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">
                    {
                      isHTR && "Category Auctions"
                    }
                    {
                      !isHTR && "Inventory Specific Auctions"
                    }
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {show &&
                    <PPCManagmentModal
                      show={show}
                      token={token}
                      isClusterInventoryCampaign={isClusterInventoryCampaign}
                      handleClose={handleClose}
                      inventoryId={inventoryCampaign?.inventory_id}
                      campaignId={campaignId}
                      updatePage={updatePage}
                      setUpdatePage={setUpdatePage}
                      clusterLevel={clusterLevel}
                      allChildrenCount={allChildrenCount}
                      anyActiveChild={anyActiveChild}
                    />
                  }
                  <CampaignBudget
                    showBottom={showBottom}
                    handleCloseBottom={handleCloseBottom}
                    campaign={campaign}
                    publisher={publisher}
                    days_diff={days_diff}
                    onChangeMonthlyBudget={onChangeMonthlyBudget}
                    onSaveBudget={onSaveBudget}
                  />
                  {
                    //^ Live PPC Auctions [DEFAULT & HTR]
                    campaign && campaign.id &&
                    <AgPPCTable
                      updatePage={updatePage}
                      getData={getPeriodLivePPCAuctions}
                      campaignId={campaign.id}
                      onClick={handleShow}
                      skin={skin}
                    />
                  }
                  {/* <CampaignTopUp
                    showBottom={showTopUpBottom}
                    handleCloseBottom={handleCloseBottom}
                    campaign={campaign}
                    publisher={publisher}
                    days_diff={days_diff}
                    onChangeMonthlyBudget={onChangeMonthlyBudget}
                    onSaveBudget={onSaveBudget}
                  /> */}
                  {/* 
                    { campaign && campaign.id &&
                      <WebsiteSpecificPPCAuctions
                        getData={getPeriodLivePPCAuctions}
                        campaignId={campaign.id}
                        onClick={handleShow}
                        skin={skin}
                      />
                    }
                  */}
                </Card.Body>
              </Card>
            </Col>
            {
              //^ Branding PPC Auctions [DEFAULT & HTR]
              campaign && campaign.id &&
              <Col md="12">
                <BrandingPPCAuctions
                  updatePage={updatePage}
                  getData={getPeriodBrandingPPCAuctions}
                  campaignId={campaign.id}
                  onClick={handleShow}
                  skin={skin}
                />
              </Col>
            }
          </Row>

          {isIframe ? <></> : <Footer />}
        </div>
      </React.Fragment>
    );
  }
}
