import React, { useEffect, useState } from "react";

import { html } from "gridjs";
import { GetPubAbbr } from "../../helpers/get-pub-abbr";
import { placeholderLogoModule } from "../../modules/placeholderLogo";
import { formatThousand } from "../../helpers/thousand-number-format";
import Header from "../../layouts/Header";
import { Breadcrumbs } from "../inventory/components/Breadcrumbs";
import { AddItemButton } from "../inventory/components/AddItemButton";
import BasicTable from "../../components/BasicTable";
import Footer from "../../layouts/Footer";
import { switchSkin } from "../../helpers/switch-skin";
import { getClientCampaigns } from "../../services/micro";
import { ImageRenderer } from "./components/ImageRenderer";
import { BillingTypeRenderer } from "./components/BillingTypeRenderer";
import { StatusRenderer } from "./components/StatusRenderer";
import { CurrentBudgetRenderer } from "./components/CurrentBudgetRenderer";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

const colWidth = {
  name: 150,
  logo_file: 100,
  account_id: 150,
  ppc_billing_type: 150,
  ppc_invoice_day: 100,
  ppc_monthly_budget: 200,
  ppc_monthly_spend: 100,
  min_cpc: 100,
  ppc_balance: 100,
};

const hiddenColumns = [
  "ppc_active",
  "ppc_paused",
  "related_categories_ids"
];

const rowFields = [
  "id",
  "name",
  "logo_file",
  "account_id",
  "ppc_billing_type",
  "ppc_invoice_day",
  "ppc_monthly_budget",
  "ppc_monthly_spend",
  "min_cpc",
  "ppc_balance",
  "ppc_active",
  "ppc_paused",
]

const customColumns = [
  {
    field: "ppc_monthly_budget",
    headerName: "Current Budget",
    cellRenderer: CurrentBudgetRenderer
  },
  {
    field: "ppc_monthly_spend",
    headerName: "Status",
    cellRenderer: StatusRenderer
  },
  {
    field: "logo_file",
    cellRenderer: ImageRenderer
  },
  {
    field: "ppc_billing_type",
    cellRenderer: BillingTypeRenderer
  },
]



export default function PublisherCampaigns() {
  const publisherIdByAbbr = GetPubAbbr();

  const title = "Campaigns"
  const breadcrumb = "Customers"
  const new_btn_title = "New Campaign"
  const new_btn_redirect = "/pub/customer/campaign/new"
  const edit_redirect = "/pub/customer/campaign/edit"
  const breadcrumb_redirect = "/pub/customers/campaigns"

  const { cookiesData } = useCookiesContext();

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const additionalInfo = {
    publisherIdByAbbr: publisherIdByAbbr,
    currentSkin: currentSkin
  }

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
          <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} />
        </div>

        <BasicTable getData={getClientCampaigns} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} customColumns={customColumns} additionalInfo={additionalInfo} skin={skin} />

        <Footer />
      </div>
    </React.Fragment>
  );
}