import React, { createContext, useState, useContext } from 'react';
import Cookies from 'universal-cookie';

export const CookiesKeyNames = {
    advertiserId: 'advertiser_id',
    abbreviation: 'abbreviation',
    clickedDeactivate: 'clickedDeactivate',
    clickedActivate: "clickedActivate",
    currentPeriod: 'currentPeriod',
    previousPeriod: 'previousPeriod',
    publisherId: 'publisher_id',
    publisherToken: 'publisher_token',
    sidebarSkin: "sidebar_skin",
    skinMode: 'skin-mode',
    token: 'token',
};

export const CookiesContext = createContext();
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");
const isIframe = token ? true : false;

export const isCookiesEnabled = isIframe ? false : navigator.cookieEnabled;

const cookies = new Cookies()

export const CookiesProvider = ({ children }) => {
    const [cookiesData, setCookiesData] = useState(() => {
        const keys = Object.values(CookiesKeyNames);
        const data = {};
        
        keys.forEach((key) => {
            if (isCookiesEnabled) {
                data[key] = cookies.get(key);
            } else {
                data[key] = localStorage.getItem(key);
            }
        });
        
        return data;
    });

    const updateCookiesProperty = (key, value) => {
        console.log('updateCookiesProperty', key, value);
        setCookiesData((prevData) => {
            const newData = { ...prevData, [key]: value };

            if (key === "currentPeriod" || key === "previousPeriod") {
                const formattedValue = JSON.stringify(value);
    
                if (isCookiesEnabled) {
                    cookies.set(key, value, { path: '/' });
                } else {
                    localStorage.setItem(key, formattedValue);
                }
            } else {
                if (isCookiesEnabled) {
                    cookies.set(key, value, { path: '/' });
                } else {
                    localStorage.setItem(key, value);
                }
            }

            return newData;
        });
    };

    const removeCookiesProperty = (key) => {
        setCookiesData((prevData) => {
            const newData = { ...prevData };
            delete newData[key];

            if (isCookiesEnabled) {
                cookies.remove(key);
            } else {
                localStorage.removeItem(key);
            }

            return newData;
        });
    };

    const clearCookies = () => {
        setCookiesData(() => {
            const keys = Object.values(CookiesKeyNames);
            const data = {};

            keys.forEach((key) => {
                if (isCookiesEnabled) {
                    cookies.remove(key);
                } else {
                    localStorage.removeItem(key);
                }
            });

            return data;
        });
    }

    return (
        <CookiesContext.Provider
            value={{ cookiesData, updateCookiesProperty, removeCookiesProperty, clearCookies }}
        >
            {children}
        </CookiesContext.Provider>
    );
};

export const useCookiesContext = () => useContext(CookiesContext);
