export function BrandingNameRenderer({ value }) {
    const name = value.split("@")[0];
    const inventory_type = value.split("@")[1];

    let type = ''

    console.log(inventory_type)

    if (inventory_type == 1) {
        type = 'DEPARTMENT'
    } else if (inventory_type == 3) {
        type = 'HOMEPAGE'
    } else if (inventory_type == 4) {
        type = 'BANNER'
    } else if (inventory_type == 5) {
        type = 'CLUSTER'
    }

    return (
        <div className='d-flex flex-column'>
            <div style={{ lineHeight: '14px', marginTop: '5px', marginBottom: '5px' }}>
                {name}
            </div>
            <div className="align-items-center" style={{ fontSize: '12px', lineHeight: '12px' }}>
                <div className='badge bg-outline-secondary badg'>{type}</div>
            </div>
        </div>
    );
}

 