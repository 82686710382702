import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector, useDispatch } from 'react-redux';
import { getUserName } from "../helpers/user-name";
import { getUserRole } from "../helpers/user-role";
import { Button, Modal } from "react-bootstrap";
import { getAllAdvUsers, getAllCustomers } from "../services/adv";
import { getAllRoles, getMe, getRecentNotifications } from "../services/micro";
import { toast } from "react-toastify";
import { setAdvertiser, clearAdvertiser } from "../features/advertiser";
import { clearUser, setUser } from '../features/user';
import readNotifications from "../services/micro/read_notifications";
import { useNavigate } from "react-router-dom";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";
import BasicTable from "../components/BasicTable";
import { generateUserTokenByAdmin, getUserAccount } from "../services/auth";
import { getPublisher } from "../services/pub";
import UsersSwitchTable from "../components/UsersSwitchTable";
import Cookies from "universal-cookie";

const PUBLISHER = 'Publisher';
const ADMIN = 'Admin';
const ADVERTISER = 'Client';

const colWidth = {
  id: 80,
  account_name: 200,
  created_at: 200,
};

const hiddenColumns = ["account_legal_name", "account_registered_address_country_id", "account_vat_number"];

const rowFields = [
  "id",
  "account_name",
  "created_at"
]

export default function Header({ onSkin = () => { } }) {
  const [modalShow, setModalShow] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [data, setData] = useState([]);
  const [userAccesses, setUserAccesses] = useState([]);
  const [subRoles, setSubRoles] = useState([]);
  const [newNotificationAmount, setNewNotificationAmount] = useState(0);
  const [advUsers, setAdvUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [advId, setAdvId] = useState(null);

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const { cookiesData, updateCookiesProperty, removeCookiesProperty } = useCookiesContext()

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";

  const modalHandleClose = () => {
    setAdvUsers([]);
    setAdvId(null);
    setModalShow(false);
    setLoading(false);
  }

  const createNewUser = () => {
    navigate('/pub/customer/user/new');
    setAdvUsers([]);
    setModalShow(false);
    setAdvId(null);
    setLoading(false);
  }

  useEffect(() => {

    getAllRoles().then((res) => {
      if (res.success) {
        setSubRoles(res.data);

        const userAccessesData = res.data.find((role) => role.name === user.role)?.avaliable_accesses;
        setUserAccesses(userAccessesData);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
      style={{ cursor: "pointer" }}
    >
      {children}
    </div>
  ));


  useEffect(() => {
    getRecentNotifications().then(res => {
      setNewNotificationAmount(res.data.new);
      setNotifications(res.data.notifications);
    }).catch(e => {
      toast.error("Server error");
      navigate('/pages/signin');
    });
  }, []);

  // todo: move it back
  // useEffect(() => {
  //   if (notifications && notifications.length && Notification.permission === "granted") {
  //     notifications.forEach((notif) => {
  //       if (!sentNotifications.has(notif.id) && notif.status !== "read" && notif.is_notified_via_push) {
  //         const newNotification = new Notification(notif.text, {
  //           icon: notif.icon_url ?? "https://revbox-email-icons.s3.eu-central-1.amazonaws.com/logo_icon-white.png",
  //         });
  //         newNotification.onclick = () => window.open("https://github.com/revbox/revbox-hub/tree/develop");
  //         setSentNotifications(prev => new Set(prev).add(notif.id));
  //       }
  //     })
  //   }
  // }, [notifications, sentNotifications]);

  const openPage = (url) => {
    window.open(url, '_blank');
  }

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  const onReadNotification = (id, link) => {
    readNotifications([id]).then(res => {
      if (link) {
        window.location.replace(link)
      } else {
        navigate('/notifications');
      }
    }).catch(() => {
      toast.error("Server error");
      navigate('/pages/signin');
    });
  }

  function NotificationList() {
    const notiList = notifications.map((item, key) => {
      return (
        <li className="list-group-item" key={key} style={item.status === "read" ? { opacity: 0.35 } : {}} onClick={() => onReadNotification(item.id, item.link)}>
          {item.icon_url ?
            <div className={(item.status === "unread") ? "avatar online" : "avatar"} style={{ backgroundImage: 'url(' + item.icon_url + ')', backgroundPosition: 'center', backgroundSize: 'contain' }} />
            :
            <div className={(item.status === "unread") ? "avatar online" : "avatar"}>
              <span class="avatar-initial">{item.title ? item.title[0] : item.text[0]}</span>
            </div>
          }
          <div className="list-group-body">
            <p>{item.title ? item.title : item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      )
    });

    return (
      <ul className="list-group">
        {notiList.length > 0 ? notiList : <li className="list-group-item">You don't have any messages yet</li>}
      </ul>
    );
  }

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      updateCookiesProperty(CookiesKeyNames.skinMode, skin);

      onSkin(skin);

    } else {
      HTMLTag.removeAttribute("data-skin");
      removeCookiesProperty(CookiesKeyNames.skinMode);
      HTMLTag.removeAttribute("data-sidebar");
      onSkin('');
    }

  };

  const systemMode = (e) => {
    e.preventDefault();

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== undefined && skin !== "advertiser") {
      HTMLTag.setAttribute("data-sidebar", skin);
      updateCookiesProperty(CookiesKeyNames.sidebarSkin, skin);
    } else {
      removeCookiesProperty(CookiesKeyNames.sidebarSkin);
    }

    if (skin === "advertiser") {
      setModalShow(true);
    } else {
      const publisher_token = cookiesData[CookiesKeyNames.publisherToken];
      const publisher_id = cookiesData[CookiesKeyNames.publisherId];
      updateCookiesProperty(CookiesKeyNames.token, publisher_token);

      getMe(publisher_token).then((res) => {
        dispatch(setUser(res.data));

        updateCookiesProperty(CookiesKeyNames.publisherId, publisher_id);
        removeCookiesProperty(CookiesKeyNames.sidebarSkin);
        dispatch(clearAdvertiser());
        removeCookiesProperty(CookiesKeyNames.advertiserId);

        modalHandleClose();

        if (user.role !== "developer" && user.role !== "content_manager" && user.role !== "sales") {
          navigate("/pub/dashboard/revenue?refresh=true&pub=" + publisher_id);
        } else {
          navigate("/pub/inventory/queue?refresh=true&pub=" + publisher_id);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  const modalHandleSwitch = ({ data }) => {
    setLoading(true);
    if (data?.id) {
      setAdvId(data.id);

      getAllAdvUsers(data.id).then((res) => {
        console.log(res.data);
        setAdvUsers([user, ...res.data]);
      }).catch((err) => {
        setAdvUsers([user]);
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  const handleUserSwitch = (user_id) => {    
    getPublisher(cookiesData[CookiesKeyNames.publisherId]).then((res) => {
      const publisher = res.data;
      const api_key = publisher.api_key;
      const old_token = cookiesData[CookiesKeyNames.token];

      updateCookiesProperty(CookiesKeyNames.publisherToken, old_token);
      if (user_id === user.id && user.role === "PUBLISHER") {
        clearAdvertiser();
        updateCookiesProperty(CookiesKeyNames.sidebarSkin, "advertiser");

        dispatch(setAdvertiser({ id: advId }));
        updateCookiesProperty(CookiesKeyNames.advertiserId, advId);

        const navigateLink = "/adv/home?refresh=true&pub=" + publisher.abbreviation;
        navigate(navigateLink);

        window.location.reload();
      }

      generateUserTokenByAdmin(api_key, user_id).then((user_token) => {
        const token = user_token.data.token;

        clearUser();
        clearAdvertiser();

        updateCookiesProperty(CookiesKeyNames.token, token);
        updateCookiesProperty(CookiesKeyNames.sidebarSkin, "advertiser");
        updateCookiesProperty(CookiesKeyNames.publisherId, publisher.id);

        dispatch(setUser(user));


        getUserAccount(advId).then((account) => {

          dispatch(setAdvertiser({ id: account.data.id }));
          updateCookiesProperty(CookiesKeyNames.advertiserId, account.data.id);

          const navigateLink = "/adv/home?refresh=true&pub=" + publisher.abbreviation;
          navigate(navigateLink);

          window.location.reload();
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
  }


  // avaliable_accesses
  // "user_account_management_access"
  // "finance_access"
  // "dashboards_access"
  // "inventory_access"
  // "advertiser_access"
  // "customers_access"
  // "user_roles_management_access"
  // "global_variables_access"
  // "webhook_access"
  // "api_key_access"
  // "notifications_access"

  const automationsPermission = userAccesses && (
    userAccesses.includes("api_key_access") ||
    userAccesses.includes("webhook_access") ||
    userAccesses.includes("ai_automations")
  );

  const globalConfigurationsPermission = userAccesses && userAccesses.includes("global_variables_access");

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      <div className="me-auto"></div>

      <Modal show={modalShow} onHide={modalHandleClose} size="xl" className="accounts-modal">
        <Modal.Header closeButton>
          <Modal.Title className="align-items-center">
            <button style={{ fontSize: "15px", cursor: "pointer" }} className="btn btn-outline-primary btn-sm me-2" title="Back" onClick={() => {
              setAdvUsers([]);
              setAdvId(null);
            }} disabled={advUsers.length === 0 && advId === null}>
              <i className="ri-arrow-left-line" title="Back"></i>
            </button>
            {advUsers.length === 0 && advId === null &&
              <>
                <b>Step 1/2</b> - Select account to switch
              </>
            }
            {
              advUsers.length > 0 && advId !== null &&
              <>
                <b>Step 2/2</b> - Select user to login
              </>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
          {advUsers.length === 0 && advId === null &&
            <BasicTable getData={getAllCustomers} modalHandleSwitch={modalHandleSwitch} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} skin={currentSkin} />
          }
          {advUsers.length > 0 && advId !== null &&
            <UsersSwitchTable users={advUsers} onClick={handleUserSwitch} skin={currentSkin} />
          }
          {advUsers.length === 0 && advId !== null && !loading &&
            <div className="accounts-list p-3">
              <h4 className="text-center">No users found, please create new user for this account</h4>
              <div className="text-center mt-3">
                <Button variant="primary" size="sm" onClick={() => createNewUser()}>Create New User</Button>
              </div>
            </div>
          }
          {
            loading &&
            <div className="text-center p-3">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          }
        </Modal.Body>
      </Modal>

      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f header-dropdown-menu">
          <label>Skin Mode</label>
          <nav className="nav nav-skin">
            <Link onClick={skinMode} className={cookiesData[CookiesKeyNames.skinMode] ? "nav-link" : "nav-link active"}>Light</Link>
            <Link onClick={skinMode} className={cookiesData[CookiesKeyNames.skinMode] ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
          {
            ((cookiesData[CookiesKeyNames.sidebarSkin] === "advertiser") &&
              (!!CookiesKeyNames.publisherToken)) &&
            <>
              <hr />
              <label>System Mode</label>
              <nav id="systemMode" className="nav nav-skin">
                <div onClick={systemMode} className={!cookiesData[CookiesKeyNames.sidebarSkin] ? "nav-link active" : "nav-link"}>Publisher</div>

                {user.role === "PUBLISHER" &&
                  <div onClick={systemMode} className={cookiesData[CookiesKeyNames.sidebarSkin] === "advertiser" ? "nav-link active" : "nav-link"}>Advertiser</div>
                }
              </nav>
            </>
          }
          {
            cookiesData[CookiesKeyNames.sidebarSkin] !== "advertiser" &&
            <>
              <hr />
              <label>System Mode</label>
              <nav id="systemMode" className="nav nav-skin">
                <div className={!cookiesData[CookiesKeyNames.sidebarSkin] ? "nav-link active" : "nav-link"}>Publisher</div>
                {(
                  userAccesses.includes("advertiser_access")
                ) && <div onClick={systemMode} className={cookiesData[CookiesKeyNames.sidebarSkin] === "advertiser" ? "nav-link active" : "nav-link"}>Advertiser</div>}
                {(
                  userAccesses.includes("admin_access")
                ) && <div onClick={systemMode} className={cookiesData[CookiesKeyNames.sidebarSkin] === "admin" ? "nav-link active" : "nav-link"}>Admin</div>}
              </nav>
            </>
          }

        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        {newNotificationAmount > 0 &&
          <Dropdown.Toggle as={CustomToggle}>
            <small>{newNotificationAmount > 9 ? '9+' : newNotificationAmount}</small><i className="ri-notification-3-line"></i>
          </Dropdown.Toggle>
        }
        {newNotificationAmount === 0 &&
          <Dropdown.Toggle as={CustomToggle}>
            <i className="ri-notification-3-line"></i>
          </Dropdown.Toggle>
        }
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h6 className="dropdown-menu-title">Notifications</h6>
            <Button variant="outline-primary" size="sm" onClick={() => openPage('/notifications/emails')}>Mail Center</Button>
          </div>
          {NotificationList()}
          <div className="dropdown-menu-footer"><Link to="/notifications">Show all Notifications</Link></div>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <span className="avatar-initial bg-primary">{getUserName(user)[0]}</span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3"><span className="avatar-initial bg-primary">{getUserName(user)[0]}</span></div>
            <h5 className="mb-1 text-dark fw-semibold">{getUserName(user)}</h5>
            <p className="fs-sm text-secondary">{getUserRole(user)}</p>

            <nav className="nav">
              {userAccesses.includes("api_key_access") &&
                <Link to="https://docs.revbox.co/docs/intro/?code=XrUo7zIhhU<" target="_blank"><i className="ri-question-line"></i> Documentation</Link>
              }
            </nav>
            <hr />
            <nav className="nav">
              {/* <Link to="/adv/settings"><i className="ri-refund-line"></i> Revbox Billing</Link> */}
              <Link to="/adv/settings"><i className="ri-user-settings-line"></i> Account Settings</Link>
              {/* {
                userAccesses.includes("customers_access") && <Link to="/pub/customer/campaign/new"><i className="ri-add-line"></i> New Campaign</Link>
              } */}
            </nav>
            {globalConfigurationsPermission &&
              <nav className="nav">
                <Link to="/adv/configurations"><i className="ri-settings-4-line"></i> Global Configurations</Link>
              </nav>
            }
            {automationsPermission &&
              <nav className="nav">
                <Link to="/adv/automations"><i className="ri-code-s-slash-line"></i> Automations</Link>
              </nav>
            }
            <hr />
            <nav className="nav">
              <Link to={`/pages/signin?pub=${user.publisher_id}&refresh=true`}><i className="ri-logout-box-r-line"></i> Log Out</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}