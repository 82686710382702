import React, { useEffect, useState } from "react";
import { getAllWebsites } from "../../services/pub";
import Header from "../../layouts/Header";
import { switchSkin } from "../../helpers/switch-skin";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { AddItemButton } from "./components/AddItemButton";
import Footer from "../../layouts/Footer";
import BasicTable from "../../components/BasicTable";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

const colWidth = {
  name: 330,
  min_cpc: 200,
  domain: 400,
  publisher_abbreviation: 200,
};

const hiddenColumns = [];
const rowFields = ["id","publisher_abbreviation", "name", "domain", "min_cpc"]

export default function PublisherWebsite() {
  const title = "Websites"
  const breadcrumb = "Inventory"
  const new_btn_title = "New Website"
  const new_btn_redirect = "/pub/website/new"
  const edit_redirect = "/pub/website/edit"
  const breadcrumb_redirect = "/pub/websites"
  const { cookiesData } = useCookiesContext();

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
          <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} />
        </div>

        <BasicTable getData={getAllWebsites} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} skin={skin} />

        <Footer />
      </div>
    </React.Fragment>
  )
}