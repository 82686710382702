import React, { useEffect, useState } from "react";
import { getPubUsers } from "../../services/micro";
import Header from "../../layouts/Header";
import { Breadcrumbs } from "../inventory/components/Breadcrumbs";
import { AddItemButton } from "../inventory/components/AddItemButton";
import BasicTable from "../../components/BasicTable";
import Footer from "../../layouts/Footer";
import { switchSkin } from "../../helpers/switch-skin";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

const colWidth = {
  account_name: 250,
  account_legal_name: 290,
  account_registered_address_country_id: 100,
  account_vat_number: 180,
  created_at: 200,
};

const hiddenColumns = [
];

const rowFields = [
  "id",
  "first_name",
  "last_name",
  "email",
  "phone_number",
  "account_id",
  "access_levels",
  "job_title",
  "country_id",
  "created_at",
]

export default function PublisherUsers() {
  const title = "Users"
  const breadcrumb = "Customers"
  const new_btn_title = "New User"
  const new_btn_redirect = "/pub/customer/user/new"
  const edit_redirect = "/pub/customer/user/edit"
  const breadcrumb_redirect = "/pub/customers/users"

  const { cookiesData } = useCookiesContext();

  const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);


  useEffect(() => {
    switchSkin(skin);
  }, [skin]);


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
          <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} />
        </div>

        <BasicTable getData={getPubUsers} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} skin={skin}/>

        <Footer />
      </div>
    </React.Fragment>
  );
}