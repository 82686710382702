import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { getServerSideDatasourcePPC } from "../../../helpers/get-serverside-datasource-ppc";
import "ag-grid-charts-enterprise";
import { PositionRenderer, PositionCellTooltipRenderer } from "./PositionRenderer";
import { StatusCellTooltipRenderer, StatusRenderer } from "./StatusRenderer";
import { ActionCellTooltipRenderer, ActionRenderer } from "./ActionRenderer";
import { MissedClicksForecastCellTooltipRenderer, MissedClicksForecastRenderer } from "./MissedClicksForecastRenderer";
import { NameRenderer, NameCellTooltipRenderer } from "./NameRenderer";
import { YourBidCellRenderer, YourBidCellTooltipRenderer } from "./YourBidRenderer";
import { TopBidCellTooltipRenderer } from "./TopBidRenderer";
import { YourClicksCellTooltipRenderer } from "./YourClicksRenderer";
import { CustomTooltip } from "./CustomTooltip";

const customColumns = [
    {
        field: "Name",
        cellRenderer: NameRenderer,
        headerTooltip: "Inventory",
        //tooltipValueGetter: NameCellTooltipRenderer
    },
    {
        field: "Position",
        cellRenderer: PositionRenderer,
        headerTooltip: "Your current position",
        tooltipValueGetter: PositionCellTooltipRenderer
    },
    {
        field: "Status",
        cellRenderer: StatusRenderer,
        headerTooltip: "Status",
        tooltipValueGetter: StatusCellTooltipRenderer
    },
    {
        field: "Your Bid",
        cellRenderer: YourBidCellRenderer,
        headerTooltip: "Your current bid",
        // tooltipValueGetter: YourBidCellTooltipRenderer
    },
    {
        field: "Top Bid",
        headerTooltip: "Current top bid",
        // tooltipValueGetter: TopBidCellTooltipRenderer
    },
    {
        field: "Your Clicks",
        headerTooltip: "Your clicks for the last 30 days",
        tooltipValueGetter: YourClicksCellTooltipRenderer
    },
    {
        field: "Action",
        cellRenderer: ActionRenderer,
        headerTooltip: "Action",
        // tooltipValueGetter: ActionCellTooltipRenderer
    },
    {
        field: "Missed Clicks Forecast",
        cellRenderer: MissedClicksForecastRenderer,
        headerTooltip: "The missed clicks meter shows you how much room there is to optimize your campaign to deliver the most amount of clicks based on your current bid and position. If the missed clicks meter is full, this means that your campaign is optimized (ie. based on your current bid you are in the #1 position and will generate the maximum amount of clicks). If the missed clicks meter is empty, this means that based on your current bid and position you will miss out on the majority of clicks generated by the ad unit unless you update your bid to improve your position.",
        tooltipValueGetter: MissedClicksForecastCellTooltipRenderer
    }
]

export const AgPPCTable = ({ getData, skin, onClick, campaignId, updatePage }) => {
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ minHeight: "200px", width: "100%" }), []);
    
    const [search, setSearch] = useState('');
    const [gridApi, setGridApi] = useState(null);
    const [columnDefs, setColumnDefs] = useState([]);
    
    const [data, setData] = useState([]);

    useEffect(() => {
        getData({ search }, campaignId).then((response) => {
            if (response.success) {
                setData(response.data.rows);
            }
        });
    }, [campaignId]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            tooltipComponent: CustomTooltip
        };
    }, []);

    const onFilterTextBoxChanged = (e) => {
        setSearch(e.target.value);
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        const datasource = getServerSideDatasourcePPC(
            getData,
            setColumnDefs,
            search,
            customColumns,
            campaignId
        );

        params.api.setGridOption("serverSideDatasource", datasource);
    }, []);


    useEffect(() => {
        if (gridApi) {
            const datasource = getServerSideDatasourcePPC(
                getData,
                setColumnDefs,
                search,
                customColumns,
                campaignId
            );

            gridApi.setGridOption("serverSideDatasource", datasource);
        }
    }, [gridApi, search, updatePage, campaignId]);

    if (!data) {
        return (<></>)
    }

    return (
        <div style={containerStyle}>
            <div
                style={gridStyle}
                className={skin === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
            >
                <div style={{ marginBottom: "10px" }}>
                    <input
                        type="text"
                        className="input-search"
                        placeholder="Search..."
                        onInput={onFilterTextBoxChanged}
                    />
                </div>
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    pagination={true}
                    tooltipShowDelay={500}
                    tooltipHideDelay={200000}
                    paginationPageSize={10}
                    paginationPageSizeSelector={false}
                    cacheBlockSize={10}
                    rowModelType={"serverSide"}
                    domLayout={"autoHeight"}
                    suppressContextMenu={true}
                    onCellClicked={onClick}
                />
            </div>
        </div>
    );
};