import React, { useEffect, useState } from "react";
import { getAllBillingMethods } from "../../services/adv";
import Header from "../../layouts/Header";
import { Breadcrumbs } from "../inventory/components/Breadcrumbs";
import { AddItemButton } from "../inventory/components/AddItemButton";
import BasicTable from "../../components/BasicTable";
import Footer from "../../layouts/Footer";
import { switchSkin } from "../../helpers/switch-skin";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

const colWidth = {
  billing_contact_name: 100,
  billing_company_name: 100,
  ppc_billing_type: 100,
  billing_registered_address_country_id: 100,
  billing_vat_number: 100,
  account_id: 100,
  campaign_id: 100,
  created_at: 100,
};

const hiddenColumns = [];

const rowFields = [
  "id",
  "billing_contact_name",
  "billing_company_name",
  "ppc_billing_type",
  "billing_registered_address_country_id",
  "billing_vat_number",
  "account_id",
  "campaign_id",
  "created_at",
]

export default function BillingMethods() {
    const title="Billing Methods"
    const breadcrumb="Billing Methods"
    const new_btn_title="New Billing Method"
    const new_btn_redirect="/pub/finance/billing/new"
    const edit_redirect="/pub/finance/billing/edit"
    const breadcrumb_redirect="/pub/finance/billing"

    const { cookiesData } = useCookiesContext();
  
    const currentSkin = cookiesData[CookiesKeyNames.skinMode] ? "dark" : "";
    const [skin, setSkin] = useState(currentSkin);

    useEffect(() => {
      switchSkin(skin);
    }, [skin]);
    
    return (
      <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
          <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} />
        </div>

        <BasicTable getData={getAllBillingMethods} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} skin={skin}/>

        <Footer />
      </div>
    </React.Fragment>
    )
}