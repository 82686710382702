import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    Card,
    Placeholder
} from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-charts-enterprise";
import { MissedClicksForecastCellTooltipRenderer, MissedClicksForecastRenderer } from "../MissedClicksForecastRenderer";
import { getServerSideDatasourceClusterPPC } from "../../../../helpers/get-serverside-datasource-cluster-ppc";
import { TopBidCellTooltipRenderer } from "../TopBidRenderer";
import { ClusterNameCellTooltipRenderer, ClusterNameRenderer } from "./ClusterNameRenderer";
import { ClusterActionCellTooltipRenderer, ClusterActionRenderer } from "./ClusterActionRenderer";
import { ClusterPositionCellTooltipRenderer, ClusterPositionRenderer } from "./ClusterPositionRenderer";
import { StatusCellTooltipRenderer, StatusRenderer } from "../StatusRenderer";
import { ClusterYourBidCellRenderer, ClusterYourBidCellTooltipRenderer } from "./ClusterYourBidRenderer";

const customColumns = [
    {
        field: "Topic",
        cellRenderer: ClusterNameRenderer,
        headerTooltip: "Inventory",
        tooltipValueGetter: ClusterNameCellTooltipRenderer
    },
    {
        field: "Position",
        cellRenderer: ClusterPositionRenderer,
        headerTooltip: "Your current position",
        tooltipValueGetter: ClusterPositionCellTooltipRenderer
    },
    {
        field: "Status",
        cellRenderer: StatusRenderer,
        headerTooltip: "Your current status",
        tooltipValueGetter: StatusCellTooltipRenderer
    },
    {
        field: "Your Bid",
        cellRenderer: ClusterYourBidCellRenderer,
        headerTooltip: "Your current bid",
        tooltipValueGetter: ClusterYourBidCellTooltipRenderer
    },
    {
        field: "Top Bid",
        headerTooltip: "Current top bid",
        tooltipValueGetter: TopBidCellTooltipRenderer
    },
    {
        field: "Action",
        cellRenderer: ClusterActionRenderer,
        headerTooltip: "Action",
        tooltipValueGetter: ClusterActionCellTooltipRenderer
    },
    {
        field: "Missed Clicks Forecast",
        cellRenderer: MissedClicksForecastRenderer,
        headerTooltip: "Missed clicks forecast for the next 30 days",
        tooltipValueGetter: MissedClicksForecastCellTooltipRenderer
    }
]

export const ClusterPPCAuctions = ({ getData, skin, onClick, campaignId, inventoryCampaign, updatePage, publisherId, setClusterActiveAuctions, setClusterInheritedAuctionsAndNotShown }) => {
    const containerStyle = useMemo(() => ({ 
        width: "100%",
        height: "100%",
    }), []);
    const gridStyle = useMemo(() => ({
        minHeight: "200px",
        width: "100%",
    }), []);
    
    const [search, setSearch] = useState('');
    const [columnDefs, setColumnDefs] = useState([]);
    const [data, setData] = useState([]);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
        };
    }, []);

    const getDataPath = useCallback((data) => data.path, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: "Topic",
            field: "name",
            pinned: "left",
            tooltipValueGetter: ClusterNameCellTooltipRenderer,
            cellRendererSelector: (params) => { return {
                component: ClusterNameRenderer
            }},
            suppressHeaderFilterButton: true,
            sortable: false,
            menuTabs: [],
            suppressMenu: true,
            minWidth: 340,
            width: 340
        }
    });

    const onCellDoubleClicked = useCallback((params) => {
        if (params.colDef.showRowGroup) {
          params.node.setExpanded(!params.node.expanded);
        }
    }, []);
    
    const onCellKeyDown = useCallback((params) => {
        if (!("colDef" in params)) {
            return;
        }
        if (!(params.event instanceof KeyboardEvent)) {
            return;
        }
        if (params.event.code !== "Enter") {
            return;
        }
        if (params.colDef.showRowGroup) {
            params.node.setExpanded(!params.node.expanded);
        }
    }, []);

    const onFilterTextBoxChanged = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        getServerSideDatasourceClusterPPC(
            getData,
            setColumnDefs,
            search,
            customColumns,
            campaignId
        ).then((rows) => {
            console.log(rows);
            const activeAuctions = rows.filter(row => row.status.indexOf("Bid Active") !== -1);
            setClusterActiveAuctions(activeAuctions);

            const inheritedAuctionsAndNotShown = rows.filter(row => {
                const inheritedStatus = row.status.indexOf("Inherited") !== -1;
                const bids_amount = Number(row.position.split("@")[2]);
                let currentPos = Number(row.position.split("@")[0]);
                let estimatedPos = Number(row.position.split("@")[1]);
                let ppc_bid_active = row.position.split("@")[3];
                let campaign_active = row.position.split("@")[4];
                let ppc_paused = row.position.split("@")[5];

                let isNotShown = false;

                if (campaign_active === "false") {
                    isNotShown = true;
                }

                if (currentPos > bids_amount || currentPos === 0) {
                    isNotShown = true;
                }

                if (estimatedPos > bids_amount || estimatedPos === 0) {
                    isNotShown = true;
                }

                if (currentPos < estimatedPos) {
                    isNotShown = false;
                }

                if (estimatedPos === "Not shown") {
                    isNotShown = true;
                }

                return inheritedStatus && isNotShown;
            });

            setClusterInheritedAuctionsAndNotShown(inheritedAuctionsAndNotShown);

            setData(rows);
        }).catch((error) => {
            console.error(error);
        });
    }, [search, campaignId, updatePage]);

    if (publisherId == 67 || publisherId == 10) {
        return <></>;
    }

    // if (data.length === 0) {
    //     return (
    //         <Placeholder as={Card.Title} animation="glow" className="h-100" style={{color: "var(--bs-body-color)"}}>
    //             <Placeholder style={{ height: 400, width: "100%" }} />
    //         </Placeholder>
    //     );
    // }

    return (
        <Card className="card-one">
            <Card.Header>
                <Card.Title as="h6">
                    Topic Cluster Auctions
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <div style={containerStyle}>
                    <div
                        style={gridStyle}
                        className={skin === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
                    >
                        <div style={{ marginBottom: "10px" }}>
                            <input
                                type="text"
                                className="input-search"
                                placeholder="Search..."
                                onInput={onFilterTextBoxChanged}
                            />
                        </div>
                        <AgGridReact
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            rowData={data}

                            rowHeight={65}
                            domLayout={"autoHeight"}
                            suppressContextMenu={true}

                            onCellClicked={onClick}

                            loading={data.length === 0}
                            treeData={true}
                            groupDefaultExpanded={-1}
                            getDataPath={getDataPath}

                            onCellDoubleClicked={onCellDoubleClicked}
                            onCellKeyDown={onCellKeyDown}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};