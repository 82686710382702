import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getInventoriesPositions() {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.get(process.env.REACT_APP_HUB_URL + '/api/missed-clicks/inventories-positions')
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getInventoriesPositions;
